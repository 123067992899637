.places-autocomplete-container {
    position: relative;
    width: 100%;
}

.places-autocomplete-input {
    width: 100%;
    padding: 8px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.autocomplete-dropdown-container {
    border: 1px solid #ccc;
    z-index: 1000;
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 4px;
    border-top: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.autocomplete-suggestion {
    background-color: #ffffff;
    padding: 8px 10px;
    cursor: pointer;
}

.autocomplete-suggestion:hover {
    background-color: #f5f5f5;
}

.places-autocomplete-container input.form-control {
    box-shadow: none;
    height: calc(2.25rem + 2px);
}

.autocomplete-dropdown-container {
    width: 100%;
}

.autocomplete-suggestion {
    padding: 0.5rem 1rem;
}

.autocomplete-dropdown-container:empty {
    display: none;
}