.collapse-header {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    border: 2px solid #ececf1 !important;
    color: #5b626b !important;
    background-color: #fff !important;
}

.open {
    background-color: #ececf1 !important;
    border: none;
}

.collapse-body {
    margin-bottom: 10px;
    padding: 1rem;
}