// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin-bottom: 8px;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}


// Filter Card
.filter-box {
  background: #f9fbfc;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #d1dce5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px; /* Reduz a margem inferior para diminuir espaço */
}

.filter-box h5 {
  font-size: 16px;
}

.filter-box .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-box .text-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
}


@media (max-width: 992px) {
  .filter-box .col-md-2,
  .filter-box .col-md-4 {
      width: 100%;
      margin-bottom: 10px;
  }
}