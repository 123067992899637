.icon-text-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-style {
    margin-bottom: 10px;
    font-size: 24px;
}
