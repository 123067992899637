// Light Theme (Agrare)
@import "./bootstrap.scss";
@import "./app.scss";

// Theme (SBS)
// @import "./bootstrap-sbs.scss";
// @import "./app-sbs.scss";

// Theme (PGG)
// @import "./bootstrap-pgg.scss";
// @import "./app-pgg.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

//RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

@import "./icons.scss";

.logo-image {
  content: $logo-image;
}

.logo-light-image {
  content: $logo-light-image;
}

.logo-image-sm {
  content: $logo-image-sm;
}

.menu-color-mobile {
  color: $menu-color-mobile;
}

.item-menu-color-intranet {
  color: $item-menu-color-intranet;
}

.login-logo {
  height: $login-logo-height;
}

.login-bg-image {
  content: $login-bg-image;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.botao-select-catalago-produto {
    display: block;
    width: 100%;
    text-align: center;
    padding: 8px 12px;
    margin: 0;
    font-size: 1rem;
    color: white;
    background-color: $primary;
    border: none;
    cursor: pointer;
    outline: none;
    transition: background-color 0.2s ease-in-out;
  
    &:hover {
      background-color: $secondary;
    }
  }


.primary-color { color: $primary }
.secondary-color { color: $secondary }
.success-color { color: $success }
.info-color { color: $info }
.warning-color { color: $warning }
.danger-color { color: $danger }
.pink-color { color: $pink }
.light-color { color: $light }
.dark-color { color: $dark }

.bg-primary-color { background-color: $primary }
.bg-secondary-color { background-color: $secondary }
.bg-success-color { background-color: $success }
.bg-info-color { background-color: $info }
.bg-warning-color { background-color: $warning }
.bg-danger-color { background-color: $danger }
.bg-pink-color { background-color: $pink }
.bg-light-color { background-color: $light }
.bg-dark-color { background-color: $dark }

.btn-primary {
  background-color: $secondary !important;
  border-color: $secondary !important;
  color: $light !important;

  &:hover {
    background-color: $primary !important;
    border-color: $primary !important;
    color: $light !important;
  }
}

.tab-dashboard, .tab-dashboard-active {
  cursor: pointer;
}

.tab-dashboard {
  color: $secondary !important;
  background-color: 'transparent' !important;
}

.tab-dashboard-active {
  color: $light !important;
  background-color: $primary !important;
}


// Theme Custom Scss for react version
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y {
  width: auto !important;
}
.rdw-editor-main {
  border: 1px solid $card-border-color !important;
  border-radius: 4px !important;
  min-height: 130px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.dataTables_filter {
  float: right;
}

.dataTables_paginate .pagination {
  float: right;
}

.rdw-editor-wrapper {
  min-height: 180px !important;
}

.ReactModal__Overlay {
  z-index: 10001 !important;
}

.react-datepicker-wrapper {
  .form-control {
    width: 405% !important;
  }
}

.dropzone {
  h3 {
    text-align: center !important;
    padding-top: 100px !important;
  }
  div {
    min-height: 200px !important;
  }
  min-height: 200px !important;
}

input[type="radio"] + label {
  cursor: pointer !important;
}

// cor item ativo menu
.topnav .navbar-nav .nav-item .nav-link.active {
  color: #FFF !important;
  @media (max-width: 991.98px){
    color: $primary !important;
  }
}
.input-color {
  color: $input-color !important;
}
.rangeslider__fill {
  background-color: $primary !important;
}
.rating-container {
  background-color: transparent !important;
}

//Calender 

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 2px;
  color: white !important;
  font-weight: 100 !important;
  padding: 8px 5px 8px 7px !important;
}
.fc-daygrid-event-dot {
  display: none !important;
}
.fc-daygrid-dot-event .fc-event-title {
  font-weight: 500 !important;
}

#external-events {
  .external-event {
    text-align: left !important;
    padding: 7px 0px 6px 22px !important;
  }
  i {
    font-size: 11px !important;
  }
}
